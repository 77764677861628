<template>
  <mp-box
    id="qon-announcement-banner"
    as="div"
    :bg-color="bannerUserStatus.status === 'trial' ? 'violet.400' : 'amber.100'"
    px="3"
    py="2"
  >
    <mp-flex justify-content="space-between">
      <mp-flex class="centered-item">
        <mp-icon
          name="warning-triangle"
          :color="bannerUserStatus.status === 'trial' ? 'white' : 'black'"
          variant="fill"
          mr="3"
        />
        <mp-box font-weight="semibold" ml="2">
          <!-- v-if="warningTrialStatus.isShow" -->
          <mp-text
            v-if="bannerUserStatus.status == 'trial'"
            as="span"
            color="white"
          >
            Your trial access expires in
            {{ countDays }} days.
            {{
              isAgent()
                ? 'Contact your company admin to subscribe and get full access to Mekari Qontak features.'
                : 'Subscribe and get full access to Mekari Qontak features by contacting our support team.'
            }}
          </mp-text>
          <!-- v-if="warningFreezeStatus.isShow" -->
          <mp-text
            v-if="bannerUserStatus.status === 'freeze'"
            as="span"
            color="black"
            >Your Qontak Omnichannel access is restricted. Please consult
            further with our support team.
          </mp-text>
          <!-- v-if="warningExpiredSoon.isShow" -->
          <mp-text
            v-if="bannerUserStatus.status === 'is_expired_soon'"
            as="span"
            color="black"
            >Your subscription expires in <b>{{ countDays }} days.</b> To keep
            full access to your subscription, renew now or consult further with
            our support team.
          </mp-text>
        </mp-box>
      </mp-flex>
      <mp-flex align="center">
        <!-- CS button -->
        <mp-button
          v-if="!isAgent()"
          size="sm"
          variant="outline"
          @click="$emit('redirect-to-customer-care')"
        >
          Contact support
        </mp-button>

        <!-- Close banner -->
        <mp-box
          v-show="!isAgent()"
          as="div"
          cursor="pointer"
          @click="$emit('on-close-banner-status', false)"
        >
          <mp-icon
            v-if="bannerUserStatus.status === 'trial'"
            name="close"
            color="white"
            variant="fill"
            mr="3"
            p="1"
          />
        </mp-box>
      </mp-flex>
    </mp-flex>
  </mp-box>
</template>

<script>
export default {
  name: 'BannerUserStatus',
  props: {
    bannerUserStatus: {
      type: Object,
      default: () => ({}),
    },
    billingInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    countDays() {
      return this.getExpiredTrial(this.billingInfo.valid_until)
    },
  },
  mounted() {
    this.onCheckBannerHeight()
  },
  methods: {
    getExpiredTrial(date) {
      const unixdate = this.$moment.unix(date).format('YYYY-MM-DD')
      const expiredDate = this.$moment(unixdate)
      const todaysDate = this.$moment()
      return expiredDate.diff(todaysDate, 'days')
    },
    onCheckBannerHeight() {
      const banner = document.querySelector('#qon-announcement-banner')
      banner &&
        this.$emit('set-banner-height', {
          type: 'user-status',
          value: banner.offsetHeight || 0,
        })
    },
  },
}
</script>
